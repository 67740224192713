const throttle = (callback, wait) => {
  let lastCallback = null;
  let lastRan = null;

  return (...args) => {
    const context = this;
    if (!lastRan) {
      callback.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastCallback);
      lastCallback = setTimeout(() => {
        if ((Date.now() - lastRan) >= wait) {
          callback.apply(context, args);
          lastRan = Date.now();
        }
      }, wait - (Date.now() - lastRan));
    }
  };
};

export default throttle;
